<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-row class="btn-row">
        <button
          class="tab-btn"
          :class="{ active: tab === 'content' }"
          @click="tab = 'content'"
        >
          <a-icon type="project" />Содержимое
        </button>

        <button
          class="tab-btn"
          :class="{ active: tab === 'photo' }"
          @click="tab = 'photo'"
        >
          <a-icon type="picture" />Фото
        </button>
      </a-row>
      <div v-show="tab === 'content'" class="main-tabs" type="card">
        <!-- lang tabs -->
        <a-row align="middle" class="flex-baseline sm-mb">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Язык:
          </a-col>
          <a-col :span="12">
            <!-- lang translit tabs -->
            <a-row align="middle" class="flex-baseline flex-wrap">
              <lang-tabs v-model="langTab" class="mb-1x" />
            </a-row>
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Организация:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form['organization_name_' + getKey]"
              placeholder="Введите организация"
            />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Адрес:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form['address_' + getKey]"
              placeholder="Введите aдрес"
            />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Краткое содержание:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form['short_content_' + getKey]"
              placeholder="Введите краткое содержание"
            />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Hомер телефона:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form.phone_number"
              v-mask="'+998 (##) ###-##-##'"
              placeholder="Введите номер телефона"
            />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Email:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form.email"
              placeholder="Введите номер телефона"
              type="email"
            />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Order:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.order" type="number" placeholder="Order" />
          </a-col>
        </a-row>
        <a-row style="margin-top: 25px">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Статус:
          </a-col>
          <a-switch v-model="form.is_active" />
        </a-row>
        <a-row align="middle" style="margin-top: 25px" type="flex">
          <a-col :span="6" style="padding: 4px 8px 15px; text-align: right">
            Cодержание :
          </a-col>
          <a-col v-show="langTab === 'oz'" :span="23" style="margin: 0 auto">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.oz"
              :initial-value="form.content.oz"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.oz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'uz'" :span="23" style="margin: 0 auto">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.uz"
              :initial-value="form.content.uz"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.uz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'qr'" :span="23" style="margin: 0 auto">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.qr"
              :initial-value="form.content.qr"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.qr = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'ru'" :span="23" style="margin: 0 auto">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.ru"
              :initial-value="form.content.ru"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.ru = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'en'" :span="23" style="margin: 0 auto">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.en"
              :initial-value="form.content.en"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.en = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="6" />
          <a-col :span="12">
            <a-row>
              <a-button style="margin-right: 10px" @click="$router.go(-1)">
                <a-icon type="close" />Отмена
              </a-button>
              <a-button type="primary" @click="submitData">
                <a-icon type="check" />Добавить
              </a-button>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <!-- photo tab -->
      <div v-show="tab === 'photo'">
        <!-- upload photo -->
        <a-row align="middle">
          <a-col
            :span="6"
            style="padding-top: 4px; text-align: right; padding-right: 8px"
          >
            Изображения
          </a-col>
          <a-col :span="12">
            <image-upload
              :with-cropper="false"
              :buttons="true"
              :list="fileList"
              upload-multiple
              multiple
              accept="image/jpeg, image/png"
              @change="handleImages"
            />
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  components: {
    editor: () => import("@tinymce/tinymce-vue"),
    "image-upload": () => import("@/components/utils/image-upload")
  },
  data: () => {
    return {
      langTab: $langPrefix,
      form: {
        organization_name_uz: "",
        organization_name_oz: "",
        organization_name_ru: "",
        organization_name_en: "",
        organization_name_qr: "",
        address_uz: "",
        address_ru: "",
        address_en: "",
        address_oz: "",
        type: 2,
        address_qr: "",
        phone_number: "",
        content: {
          ru: "",
          en: "",
          uz: "",
          oz: "",
          qr: ""
        },
        email: "",
        images: [],
        order: "",
        is_active: false
      },
      fileList: [],
      image: null,
      tab: "content",
      icon: ""
    }
  },
  computed: {
    getKey() {
      return this.langTab
    },
    isContentNull() {
      return !!(
        !this.form.content.uz &&
        !this.form.content.oz &&
        !this.form.content.ru &&
        !this.form.content.qr &&
        !this.form.content.en
      )
    }
  },
  methods: {
    async submitData() {
      try {
        let form = this.form
        form = {
          ...form,
          content_ru: form.content.ru,
          content_uz: form.content.uz,
          content_oz: form.content.oz,
          content_qr: form.content.qr,
          content_en: form.content.en
        }
        delete form.slug
        delete form.thumb
        delete form.content
        if (typeof form.images === "string") {
          delete form.images
        }
        let f = new FormData()
        Object.keys(form).forEach((k) => {
          k !== "images" && f.append(k, form[k])
        })
        form.images.forEach((k) => {
          f.append("images", k.fileTarget, k.fileTarget.name)
        })
        await this.$api.post("/admin/structure/organization/create/?type=2", f)
        this.$message.success("Цифровизация создан успешно")
        this.$router.push({ name: "organization" })
      } catch (e) {
        console.log(e)
      }
    },
    handleImages(arg) {
      this.form.images.push(arg[0])
      console.log(this.form.images, arg[0])
    }
  }
}
</script>

<style>
.form_image > .org_image {
  width: 80px;
  height: 80px;
  border: 1px solid grey;
  border-radius: 50%;
  overflow: hidden;
  display: inline;
  margin-right: 20px;
}
.form_image > .org_image > img {
  width: 100%;
  height: 100%;
}
.form_image {
  margin: 10px 0;
  height: 70px;
  display: flex;
  align-items: center;
}
</style>
